<template>
  <Overview 
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="current_date"
        :sortOrder=-1
        :newButtonName="$t('form.add', [$t(navName)])"
        :selectFields=selectFields
        :filterOptions="filterOptions"
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="current_date" :header="$t('overview.date')" :sortable="true" style="width: 120px">
              <template #body="slotProps">
                {{formatDate(slotProps.data.current_date, 'shortDate')}}
              </template>
            </Column>
            <Column field="location.full_name" :header="$t('navigation.locations')" :sortable="false">
              <template #body="slotProps">
                <div class="uploaded-logo uploaded-logo-sm" :style="{'background-image': `url(${slotProps.data.location.icon_url || (slotProps.data.location.franchise ? slotProps.data.location.franchise.icon_url : false)})`}"></div>
                <span>{{slotProps.data.location.full_name}}</span>
                <span v-for="tag in slotProps.data.tags" :key="tag.name" class="pill bg-unidark text-light mx-2">{{tag.name}}</span>
              </template>
            </Column>
            <Column field="name" :header="$t('locationtabs.step_3')" :sortable="false" style="width: 10%">
              <template #body="slotProps">
                <div class="d-flex gap-2 align-items-center">
                  <div v-if="slotProps.data.location && slotProps.data.location.bookkeeping_system_location && slotProps.data.location.bookkeeping_system_location.bookkeeping_system_auth" 
                    class="pill text-light" :class="getBStatus(slotProps.data.location.bookkeeping_system_location)" :data-bs-toggle="getBTooltip(slotProps.data.location)" :data-bs-title="getExpiredMessage(slotProps.data.location)"
                    >
                      {{slotProps.data.location.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system.name}}
                  </div>
                </div>
              </template>
            </Column>
            <Column field="location.check_daystates" :header="$t('overview.check')" :sortable="true" style="width: 130px; text-align: center">
              <template #body="slotProps">
                  <i v-show="slotProps.data.location.check_daystates" class="far fa-check text-success"></i>
              </template>
            </Column>
            <Column field="status" :header="$t('overview.status')" :sortable="false" style="width: 150px; text-align: center">
              <template #body="slotProps">
                  <div class="text-center" v-html="getStatus(slotProps.data)" :data-bs-toggle="setTooltip(slotProps.data, 0)" :data-bs-title="setTooltip(slotProps.data)"></div>
              </template>
            </Column>
            <Column field="profit" :header="$t('overview.income')" :sortable="false" style="width: 150px">
              <template #body="slotProps">
                {{formatCurrency(slotProps.data.total_payment || "0.00", true)}}
              </template>
            </Column>
            <Column field="revenue" :header="$t('overview.revenue')" :sortable="false" style="width: 150px">
              <template #body="slotProps">
                {{formatCurrency(slotProps.data.total_turnover || "0.00", true)}}
              </template>
            </Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import dsService from '@/services/DaystateService'
import csService from '@/services/CustomerService'
import bookService from '@/services/BookkeepingService'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: dsService,
            itemFormRef: null,
            companyTypes: [],
            selectFields: [],
            bookkeepingsystems: [],
            navName: 'navigation.daystates',
            filterOptions: []
        }
    },
    mounted() {
        bookService.index().then(response => {
          this.bookkeepingsystems = response.data;
          this.bookkeepingsystems.sort((a, b) => a.name.localeCompare(b.name));
          this.setFilters();
        });
        this.$nextTick(() => {
          const els = document.querySelectorAll('.p-sortable-column');
          els.forEach(el => {
            el.addEventListener('mousedown', this.alterSort);
          });
        });
    },
    methods: {
        setFilters(){
          this.filterOptions = [
                {
                    field: 'current_date',
                    name: this.$t('overview.period'),
                    date: true
                },
                {
                    field: 'location.id',
                    nameField: 'full_name',
                    service: csService,
                    name: this.$t('navigation.locations'),
                    multiple: true
                },
                {
                    field: 'location.bookkeepingSystemLocation.bookkeepingSystemAuth.bookkeeping_system_id',
                    options: this.bookkeepingsystems,
                    name: this.$t('locationtabs.step_5')
                },
                {
                    field: 'status',
                    checkboxes: [
                        {id: 'ok', name: "Geëxporteerd"},
                        {id: 'import_pending', name: "Aan het importeren"},
                        {id: 'import_warning', name: "Waarschuwing bij importeren"},
                        {id: 'import_error', name: "Fout bij importeren"},
                        {id: 'export_pending', name: "Aan het exporteren"},
                        {id: 'export_warning', name: "Waarschuwing bij exporteren"},
                        {id: 'export_error', name: "Fout bij exporteren"}
                    ],
                    name: this.$t('overview.status')
                }
            ];
            this.$nextTick(() => {
                this.$refs.overview.$refs.overviewfilters.setFilters();
                if(this.savedFilters){
                  const newFilters = [];
                  for(let i in this.filterOptions){
                    let newFilter = {};
                    for(let key in this.filterOptions[i]){
                      newFilter[key] = this.filterOptions[i][key];
                    }
                    for(let key in this.savedFilters[i]){
                      newFilter[key] = this.savedFilters[i][key];
                    }
                    newFilters.push(newFilter);
                  }
                  this.$refs.overview.$refs.overviewfilters.filterData = newFilters;
                  this.$refs.overview.$refs.overviewfilters.save();
                }
            });
        },
        setTooltip(data, setTile = true){
          if(data.last_error && data.status.includes('error')){
            return setTile ? data.last_error : 'tooltip';
          }
          if(data.import_sync || data.export_sync){
            if(setTile){
              let returndata = '';
              if(data.import_sync){
                returndata += `Import:\n${this.formatDate(data.import_sync, 'statusDateTime')}`;
              }
              if(data.export_sync){
                returndata += `${returndata ? '\n\n' : ''}Export:\n${this.formatDate(data.export_sync, 'statusDateTime')}`;
              }
              return returndata;
            }
            return 'tooltip';
          }
          return null;
        },
        getStatus(data){
          if(!data.status) return '';
          const statuses = [
            {"name": "ok", "class": "bg-success text-light", "inner": "OK"},
            {"name": "import_pending", "class": "bg-secondary text-light", "inner": "<i class=\"far fa-clock\"></i> import"},
            {"name": "import_warning", "class": "bg-warning text-light", "inner": "<i class=\"far fa-warning\"></i> import"},
            {"name": "import_error", "class": "bg-danger text-light", "inner": "<i class=\"far fa-warning\"></i> import"},
            {"name": "export_pending", "class": "bg-unidark text-light", "inner": "<i class=\"far fa-clock\"></i> export"},
            {"name": "export_warning", "class": "bg-warning text-light", "inner": "<i class=\"far fa-warning\"></i> export"},
            {"name": "export_error", "class": "bg-danger text-light", "inner": "<i class=\"far fa-warning\"></i> export"},
            {"name": "exported", "class": "bg-success text-light", "inner": "<i class=\"far fa-check default-color\"></i> export"}
          ];
          //get status by name
          const status = statuses.find(s => s.name === data.status);
          if(!status) return '';
          return `<span class="pill status-pill ${status.class}">${status.inner}</span>`;
        },
        getBStatus(data){
          const statuses = {"active": "bg-unidark", "error": "bg-danger", "pending": "bg-secondary", "inactive": "bg-secondary"};
          return statuses[data.status] || 'bg-unidark';
        },
        getBTooltip(data){
          return data.bookkeeping_system_location.bookkeeping_system_auth.expired ? 'tooltip' : null;
        },
        getExpiredMessage(data){
          return data.bookkeeping_system_location.bookkeeping_system_auth.expired ? this.$t('overview.expired_notice') : '';
        },
        alterSort(){
          this.$refs.overview.sortAltered = true;
        }
    }
    
}
</script>
<style scoped>
.dlogo{
  width: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.rlogo{
  border-radius: 100px;
}
</style>